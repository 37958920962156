import React from 'react';

interface ProgressBarProps {
  total: number;
  value: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ total, value }) => {
  const percentage = Math.min((value / total) * 100, 100);

  return (
    <div
      style={{
        width: '100%',
        margin: '10px 0',
        backgroundColor: '#ffffff',
        padding: '10px',
        borderRadius: '10px',
        position: 'relative',
      }}
    >
      {/* Labels above the bar */}
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
          marginBottom: '5px',
          fontSize: '12px',
          fontWeight: 'bold',
          color: '#555',
        }}
      >
        {`${value}/${total} claimed`}
      </div>

      {/* Bar container */}
      <div
        style={{
          width: '100%',
          backgroundColor: '#e0e0e0',
          borderRadius: '10px',
          height: '20px',
          position: 'relative',
          border: '2px solid #000000',
        }}
      >
        {/* Filled part */}
        <div
          style={{
            width: `${percentage}%`,
            height: '100%',
            backgroundColor: '#008060',
            borderRadius: '10px 0 0 10px',
            transition: 'width 0.3s ease',
          }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
