import React, { useEffect, useMemo, useState } from 'react';
import { Filters, ResourceList } from '@shopify/polaris';
import { LocalPagination, determinePageSize } from 'components/LocalPagination';
import styles from './ReportsList.module.scss';
import { Contract } from '../../hooks/useGetContracts';
import { SortOptions, SortType } from '../../types/Utils';
import { orderBy } from 'lodash';
import ReportsListItem from 'components/ReportListItem/ReportsListItem';

const ReportsList: React.FC<{ contracts: Contract[] }> = ({ contracts }) => {
  const pageSize = 10;
  const filteredContracts = useMemo(
    () => contracts.filter((contract) => !contract.pending),
    [contracts]
  );
  const numberOfPages = Math.ceil(filteredContracts.length / pageSize);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortValue, setSortValue] = useState<SortType>('UPDATED_AT');
  const [queryValue, setQueryValue] = useState<string | undefined>(undefined);
  const [contractsAvailable, setContractsAvailable] =
    useState<Contract[]>(filteredContracts);
  const [contractsAvailableAndSorted, setContractsAvailableAndSorted] =
    useState<Contract[]>(filteredContracts);

  useEffect(() => {
    switch (sortValue) {
      case 'NAME_ASC':
        setContractsAvailableAndSorted(
          orderBy(contractsAvailable, (c) => c.name.toLowerCase(), 'asc')
        );
        break;
      case 'CREATED_AT':
        setContractsAvailableAndSorted(
          orderBy(contractsAvailable, 'createdAt', 'desc')
        );
        break;
      case 'UPDATED_AT':
        setContractsAvailableAndSorted(
          orderBy(contractsAvailable, 'updatedAt', 'desc')
        );
        break;
    }
  }, [sortValue, contractsAvailable]);

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={[]}
      onQueryChange={(value) => setQueryValue(value)}
      onQueryClear={() => setQueryValue(undefined)}
      onClearAll={() => setQueryValue(undefined)}
    />
  );

  useEffect(() => {
    if (queryValue) {
      setContractsAvailable(
        filteredContracts.filter((contract) =>
          contract.name.toLowerCase().includes(queryValue.toLowerCase())
        )
      );
    } else {
      setContractsAvailable(filteredContracts);
    }
  }, [queryValue, filteredContracts]);

  return (
    <>
      <ResourceList
        sortValue={sortValue}
        sortOptions={SortOptions}
        onSortChange={(selected: SortType) => {
          setSortValue(selected);
        }}
        filterControl={filterControl}
        items={determinePageSize(
          contractsAvailableAndSorted,
          currentPage,
          pageSize,
          numberOfPages
        )}
        renderItem={(item) => {
          return (
            <>
              {item && (
                <ReportsListItem
                  resourceId={item.id}
                  name={item.name}
                  address={item.address}
                  blockchain={item.blockchain}
                />
              )}
            </>
          );
        }}
      />
      <div className={`${styles.pagination}`}>
        <LocalPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          numberOfPages={numberOfPages}
        />
      </div>
    </>
  );
};

export default ReportsList;
