import React from 'react';
import { Card, Page } from '@shopify/polaris';
import Footer from 'components/Footer';
import useGetContracts from 'hooks/useGetContracts';
import styles from './ReportsPageList.module.scss';
import ReportsList from 'components/ReportsList/ReportsList';

const ReportsPageList = () => {
  const { data } = useGetContracts('ALL');

  return (
    <>
      <Page title={'Reports'}>
        {data && data?.contracts?.length === 0 ? (
          <>
            <div>no contracts to report on</div>
          </>
        ) : (
          <div className={`${styles['mx-4']}`}>
            <Card>
              <ReportsList contracts={data?.contracts || []} />
            </Card>
          </div>
        )}
      </Page>
      <Footer />
    </>
  );
};

export default ReportsPageList;
