import React from 'react';
import { TextStyle, ResourceItem, Button } from '@shopify/polaris';
import styles from './ReportsListItem.module.scss';
import BlockchainAddress from 'components/BlockchainAddress';
import t from 'lib/translation';
import { Blockchain } from 'types/Blockchain';
import { useHistory } from 'react-router-dom';
import BlockchainLogo from 'components/BlockchainLogo';

export type ContractListItemProps = {
  /**
   * The ID of the resource - for drops this is the drop ID, for contracts it's the contract ID
   */
  resourceId: string;
  name: string;
  address: string | null;
  blockchain: Blockchain;
};

const ReportsListItem: React.FC<ContractListItemProps> = ({
  resourceId,
  name,
  address,
  blockchain,
}) => {
  const history = useHistory();

  return (
    <>
      <ResourceItem
        id={resourceId}
        onClick={() => {}}
        media={<BlockchainLogo blockchain={blockchain} />}
      >
        <div className={`${styles.row}`}>
          <div className="flex flex-row">
            <TextStyle variation="strong">{name}</TextStyle>
            <TextStyle variation="strong">
              <>&nbsp;</>
            </TextStyle>
            {address ? (
              <div>
                <BlockchainAddress address={address} blockchain={blockchain} />
              </div>
            ) : (
              <div>
                <TextStyle variation="strong">
                  {t('contractsPage.pendingAddress')}
                </TextStyle>
              </div>
            )}
          </div>

          <div className={`${styles.expander}`}>
            <div className={`${styles.details}`}>
              <p>{t(`blockchain.${blockchain}`)}</p>
            </div>
            <div
              className={`${styles['px-8']}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Button
                disabled={!address}
                onClick={() =>
                  history.push(`/reports/nft/${blockchain}/${address}`)
                }
              >
                View
              </Button>
            </div>
          </div>
        </div>
      </ResourceItem>
    </>
  );
};

export default ReportsListItem;
