import React, { useState } from 'react';
import {
  Card,
  Icon,
  Modal,
  Stack,
  TextStyle,
  DisplayText,
} from '@shopify/polaris';
import { CancelSmallMinor } from '@shopify/polaris-icons';
import style from './GettingStartedWithVerisartBanner.module.scss';
import t from 'lib/translation';
import mixpanel from 'mixpanel-browser';
import { getPlatform } from 'containers/AppBridge';

interface GettingStartedWithVerisartBannerProps {
  dismiss: (dismissValue: string) => void;
}
export const GettingStartedWithVerisartBanner: React.FC<
  GettingStartedWithVerisartBannerProps
> = ({ dismiss }) => {
  const [modalOpen, setModal] = useState(false);
  const close = () => setModal(false);
  const open = () => setModal(true);

  return (
    <>
      <Modal large open={modalOpen} onClose={close} title={<></>} noScroll>
        <Modal.Section>
          <iframe
            title={'Getting Started with Verisart'}
            className={style.embedded_video}
            src="https://player.hihaho.com/embed/c35d322b-9d8a-4a82-b511-7c18aa2b0861 "
            frameBorder="0"
            allowFullScreen
            allow="fullscreen"
          />
        </Modal.Section>
      </Modal>
      <div onClick={open} className={style.getting_started_card}>
        <Card>
          <Card.Section>
            <Stack wrap={false}>
              <Stack.Item fill>
                <Stack alignment={'center'} wrap={false}>
                  <Stack.Item fill>
                    <Stack vertical>
                      <DisplayText size={'small'}>
                        {t('dashboard.gettingStartedBanner.title')}
                      </DisplayText>
                      <TextStyle variation="subdued">
                        {t('dashboard.gettingStartedBanner.message')}
                      </TextStyle>
                    </Stack>
                  </Stack.Item>
                  <Stack>
                    <img
                      alt="getting started with verisart"
                      src="/images/getting_started_video_preview.png"
                      style={{ maxHeight: '100px' }}
                    />
                  </Stack>
                </Stack>
              </Stack.Item>
              <Stack.Item>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    dismiss(new Date().toISOString());
                    mixpanel.track('Video Viewed', {
                      platform: getPlatform(),
                      'Video Type': 'Getting Started',
                    });
                  }}
                >
                  <Icon source={CancelSmallMinor} />
                </div>
              </Stack.Item>
            </Stack>
          </Card.Section>
        </Card>
      </div>
    </>
  );
};
