import React, { useState, useEffect } from 'react';
import {
  TextStyle,
  ResourceItem,
  Card,
  Button,
  Stack,
  Badge,
} from '@shopify/polaris';
import styles from './ContractListItem.module.scss';
import { MinusIcon, PlusIcon, EtherscanIcon } from 'components/JsxIcons';
import BlockchainAddress from 'components/BlockchainAddress';
import t, { parameters as p } from 'lib/translation';
import { BasescanIcon } from 'components/JsxIcons';
import {
  Blockchain,
  BlockchainClass,
  blockchainToClass,
} from 'types/Blockchain';
import { openSeaAssetsBaseUrl, scannerBaseUrl } from 'utils/blockchain';
import { OwnerStatus } from '../../hooks/useGetContracts';
import PolygonscanIcon from 'components/JsxIcons/Polygonscan/Polygonscan-Icon';
import OpenseaIcon from '../JsxIcons/Opensea/Opensea-Icon';
import { useHistory } from 'react-router-dom';
import BlockchainLogo from '../BlockchainLogo';
import ReportIcon from 'components/JsxIcons/Report/Report-Icon';
import { useFeature } from '@optimizely/react-sdk';

export type ContractListItemProps = {
  /**
   * The ID of the resource - for drops this is the drop ID, for contracts it's the contract ID
   */
  resourceId: string;
  name: string;
  symbol: string | undefined;
  address: string | null;
  blockchain: Blockchain;
  subtitle: string;
  mode: 'DROPS' | 'CONTRACTS';
  ownerAddress: string | undefined;
  description: string | undefined;
  ownerStatus: OwnerStatus | undefined;
  openseaIndexed: boolean;
  typeOfDrop?: 'preminted' | 'onDemand' | 'both';
  hasIssue?: boolean;
};

const contractType = 'ERC-721';

const ContractListItem: React.FC<ContractListItemProps> = ({
  resourceId,
  name,
  symbol,
  address,
  blockchain,
  subtitle,
  ownerAddress,
  mode,
  description,
  ownerStatus,
  openseaIndexed,
  typeOfDrop,
  hasIssue,
}) => {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const [NFTReportFlag] = useFeature('nft_report');

  useEffect(() => {
    return setExpanded(false);
  }, []);

  const dropType = (type: string) => {
    switch (type) {
      case 'preminted':
        return <p>{t('contractsPage.listItems.preminted')}</p>;
      case 'onDemand':
        return <p>{t('contractsPage.listItems.onDemand')}</p>;
      case 'both':
        return (
          <p>
            {t('contractsPage.listItems.onDemand')},{' '}
            {t('contractsPage.listItems.preminted')}
          </p>
        );
    }
  };

  return (
    <>
      <ResourceItem
        id={resourceId}
        onClick={() => setExpanded(!expanded)}
        media={<BlockchainLogo blockchain={blockchain} />}
      >
        <div className={`${styles.row}`}>
          <div className="flex flex-row">
            <TextStyle variation="strong">{name}</TextStyle>
            <TextStyle variation="strong">
              <>&nbsp;</>
              {symbol ? `(${symbol})` : ''}
            </TextStyle>
            {address ? (
              <div>
                <BlockchainAddress address={address} blockchain={blockchain} />
              </div>
            ) : (
              <div>
                <TextStyle variation="strong">
                  {t('contractsPage.pendingAddress')}
                </TextStyle>
              </div>
            )}
          </div>

          <div className={`${styles.expander}`}>
            <div>
              {hasIssue && (
                <Badge status="warning">{t('info.dropNeedsReview')}</Badge>
              )}
            </div>
            <div className={`${styles.details}`}>
              <p>{subtitle}</p>
            </div>

            <div
              className={`${styles['px-8']}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Button
                onClick={() =>
                  history.push(`/${mode.toLowerCase()}/edit/${resourceId}`)
                }
              >
                Edit
              </Button>
            </div>
            {expanded ? <MinusIcon /> : <PlusIcon />}
          </div>
        </div>
      </ResourceItem>
      {expanded && (
        <Card sectioned subdued>
          <div className={`${styles['row-at-tablet']}`}>
            <Stack vertical>
              {typeOfDrop && (
                <>
                  <Stack vertical>
                    <div className={`${styles.column}`}>
                      <TextStyle variation="subdued">
                        {t('contractsPage.listItems.dropType')}
                      </TextStyle>
                      {dropType(typeOfDrop)}
                    </div>
                  </Stack>
                </>
              )}
              <div className={`${styles.column}`}>
                <TextStyle variation="subdued">
                  {t('contractsPage.listItems.contractType')}
                </TextStyle>
                <p>{contractType}</p>
              </div>
            </Stack>

            <Stack vertical>
              {ownerAddress && (
                <div className={`${styles.column}`}>
                  <TextStyle variation="subdued">
                    {t('contractsPage.listItems.deployer')}
                  </TextStyle>
                  {/* we dont currently know the name of the owner */}
                  {ownerStatus === OwnerStatus.OWNED && <p>{'You'}</p>}
                  <BlockchainAddress
                    address={ownerAddress}
                    blockchain={blockchain}
                  />
                </div>
              )}
              {description && (
                <div className={`${styles['max-w-sm']} ${styles.column}`}>
                  <TextStyle variation="subdued">
                    {t('contractsPage.listItems.description')}
                  </TextStyle>
                  <p>{description}</p>
                </div>
              )}
            </Stack>
            {address && (
              <Stack vertical>
                <div className={`${styles['scan-width']}`}>
                  {blockchainToClass(blockchain) === BlockchainClass.POLYGON ? (
                    <a
                      className={`${styles.column} ${styles['link-text']} ${styles['centered-spaced']}`}
                      href={`${scannerBaseUrl(blockchain)}/address/${address}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PolygonscanIcon />
                      <p>{p('contractsPage.listItems.viewScan', 'Polygon')}</p>
                    </a>
                  ) : blockchainToClass(blockchain) ===
                    BlockchainClass.ETHEREUM ? (
                    <a
                      className={`${styles.column} ${styles['link-text']} ${styles['centered-spaced']} `}
                      href={`${scannerBaseUrl(blockchain)}/address/${address}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <EtherscanIcon />
                      <p>{p('contractsPage.listItems.viewScan', 'Ether')}</p>
                    </a>
                  ) : blockchainToClass(blockchain) === BlockchainClass.BASE ? (
                    <a
                      className={`${styles.column} ${styles['link-text']} ${styles['centered-spaced']} `}
                      href={`${scannerBaseUrl(blockchain)}/address/${address}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BasescanIcon />
                      <p>{p('contractsPage.listItems.viewScan', 'Base')}</p>
                    </a>
                  ) : null}
                </div>
                <Stack vertical spacing="extraTight">
                  <a
                    className={`${
                      openseaIndexed
                        ? styles['link-text']
                        : styles['disabled-text']
                    } ${styles.column} ${styles['centered-spaced']}`}
                    href={`${openSeaAssetsBaseUrl(blockchain)}/${address}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <OpenseaIcon />
                    <p>{t('externalLinks.opensea')}</p>
                  </a>
                  {!openseaIndexed && (
                    <TextStyle variation="subdued">
                      {t('externalLinks.helper')}
                    </TextStyle>
                  )}
                </Stack>
                {NFTReportFlag && (
                  <Stack>
                    <div
                      className={`${styles['link-text']} ${styles.column} ${styles['centered-spaced']}`}
                      onClick={() =>
                        history.push(`/reports/nft/${blockchain}/${address}`)
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <ReportIcon />
                      <p>View report</p>
                    </div>
                  </Stack>
                )}
              </Stack>
            )}
            {/* <div
              className={`${styles['detail-button']}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
               <Button>Edit</Button> 
            </div> */}
          </div>
        </Card>
      )}
    </>
  );
};

export default ContractListItem;
