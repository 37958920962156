import React from 'react';
import useGetDrops from '../../hooks/useGetDrops';
import ReportsPageList from './ReportsPageList';
import LoadingPage from '../../auth/LoadingPage';
export const ReportsPageLoader: React.FC = () => {
  const { data } = useGetDrops();

  if (data === undefined) return <LoadingPage />;

  return <ReportsPageList />;
};
