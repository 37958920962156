import { useQuery } from 'react-query';
import API from '../utils/api';
import { Blockchain } from 'types/Blockchain';

export interface GetReportResponse {
  id: string;
  issuerId: string;
  orderName: string;
  customerAccountId: string;
  status: 'RESERVED' | 'COMPLETE';
  request: string;
  mintingTo: string | null;
  completedAt: string | null;
  ownerEmail: string;
  isCustodial: boolean;
  isPredefined: boolean;
  transactionAddress: string | null;
  createdAt: string;
  updatedAt: string;
  mintingStarted: string | null;
  lastEmailSentAt: string | null;
  totalEmailCount: number;
  tokenId: string[];
}

export default function useGetDropReport(
  blockchain: Blockchain,
  address: string
) {
  const { data, refetch } = useQuery<GetReportResponse[]>(
    'report',
    async () => {
      return (await API().get(`shopify/report/${blockchain}/${address}`)).data;
    }
  );

  return { data, refetch };
}
