import React, { useState } from 'react';
import { TextStyle, ResourceItem, Button, Card, Stack } from '@shopify/polaris';
import styles from './ReportListItem.module.scss';
import { scannerBaseUrl } from 'utils/blockchain';
import { Blockchain } from 'types/Blockchain';
import useResendEmail, { ResendEmailValues } from 'hooks/useResendEmail';
import { MinusIcon, PlusIcon } from 'components/JsxIcons';
import axios from 'axios';
import { waitTime, waitTimeUnit } from 'pages/ReportsPage/NFTReportPage';

export type ReportListItemProps = {
  id: string;
  mintingTo: string | null;
  completedAt: string | null;
  ownerEmail: string;
  status?: 'RESERVED' | 'COMPLETE';
  isCustodial: boolean;
  isPredefined: boolean;
  transactionAddress: string | null;
  blockchain: Blockchain;
  mintingStarted: string | null;
  onSuccess: () => void;
  onFail: () => void;
  setErrorMessage: (message: string) => void;
  orderName: string | null;
  createdAt: string;
  totalEmailCount: number;
  lastEmailSentAt: string | null;
  tokenId: string[];
  refetchReports: () => void;
  isDisabled: boolean;
};

const ReportListItem: React.FC<ReportListItemProps> = ({
  id,
  ownerEmail,
  status,
  blockchain,
  transactionAddress,
  isCustodial,
  isPredefined,
  mintingStarted,
  onSuccess,
  onFail,
  orderName,
  createdAt,
  mintingTo,
  completedAt,
  tokenId,
  totalEmailCount,
  lastEmailSentAt,
  setErrorMessage,
  refetchReports,
  isDisabled,
}) => {
  const { mutateAsync: resendEmail, isLoading } = useResendEmail();
  const [emailSent, setEmailSent] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const submitForm = async (values: ResendEmailValues) => {
    try {
      await resendEmail({
        shopNFTMintId: values.shopNFTMintId,
        isCustodial: values.isCustodial,
        isPredefined: values.isPredefined,
      });
      onSuccess();
      setEmailSent(true);
      refetchReports();
    } catch (err: unknown) {
      onFail();
      setEmailSent(false);
      const errorMessage =
        axios.isAxiosError(err) &&
        err.response?.data?.message.includes(`${waitTime} ${waitTimeUnit}`)
          ? err.response.data.message
          : 'There was an error when trying to resend the email. If the issue persists, please contact support@verisart.com.';
      setErrorMessage(errorMessage);
    }
  };

  return (
    <>
      <ResourceItem id={id} onClick={() => setExpanded(!expanded)}>
        <div className={`${styles.row}`}>
          <div className="flex flex-row justify-center items-center">
            <TextStyle variation="strong">{orderName}</TextStyle>
            <TextStyle variation="subdued"> </TextStyle>
            <TextStyle variation="strong">{ownerEmail || ''}</TextStyle>
            {status === 'COMPLETE' && (
              <TextStyle variation="subdued">
                <a
                  className={`${styles.column} ${styles['link-text']} ml-1`}
                  href={`${scannerBaseUrl(
                    blockchain
                  )}/tx/${transactionAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View transaction
                </a>
              </TextStyle>
            )}
          </div>
          <div className={`${styles.expander}`}>
            <div
              className={`${styles['px-8']}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div style={{ minWidth: '125px' }}>
                {' '}
                {/* Set min-width on the wrapper div */}
                <Button
                  fullWidth
                  disabled={
                    status === 'COMPLETE' ||
                    mintingStarted !== null ||
                    isDisabled
                  }
                  onClick={() =>
                    submitForm({ shopNFTMintId: id, isCustodial, isPredefined })
                  }
                  loading={isLoading}
                >
                  {status === 'COMPLETE'
                    ? 'Claimed'
                    : mintingStarted !== null && status === 'RESERVED'
                    ? 'Claiming'
                    : emailSent
                    ? 'Sent'
                    : 'Resend email'}
                </Button>
              </div>
            </div>
            {expanded ? <MinusIcon /> : <PlusIcon />}
          </div>
        </div>
      </ResourceItem>
      {expanded && (
        <Card sectioned subdued>
          <div className={`${styles['row-at-tablet']}`}>
            <Stack vertical>
              {orderName && (
                <Stack vertical>
                  <div className={`${styles.column}`}>
                    <TextStyle variation="subdued">Order Name</TextStyle>
                    {orderName}
                  </div>
                </Stack>
              )}
              <div className={styles.column}>
                <TextStyle variation="subdued">Date purchased (UTC)</TextStyle>
                <p>
                  {new Date(createdAt).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true,
                  })}
                </p>
              </div>
            </Stack>

            {tokenId && tokenId[0] !== '' && (
              <div className={`${styles.mobileColumnPadding}`}>
                <Stack vertical>
                  <div className={`${styles.column}`}>
                    <TextStyle variation="subdued">Token Id</TextStyle>
                    <p>{tokenId}</p>
                  </div>
                </Stack>
              </div>
            )}

            {(completedAt || (mintingTo && completedAt) || lastEmailSentAt) && (
              <div className={`${styles.mobileColumnPadding}`}>
                <Stack vertical>
                  {completedAt && (
                    <div className={`${styles['max-w-sm']} ${styles.column}`}>
                      <TextStyle variation="subdued">
                        Date claimed (UTC)
                      </TextStyle>
                      <p>
                        {new Date(completedAt).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: true,
                        })}
                      </p>
                    </div>
                  )}
                  {mintingTo && completedAt && (
                    <div className={`${styles.column}`}>
                      <TextStyle variation="subdued">
                        Claimed by address
                      </TextStyle>
                      <p>{mintingTo}</p>
                    </div>
                  )}
                  {lastEmailSentAt && (
                    <div className={`${styles['max-w-sm']} ${styles.column}`}>
                      <TextStyle variation="subdued">
                        Last email sent at (UTC)
                      </TextStyle>
                      <p>
                        {new Date(lastEmailSentAt).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: true,
                        })}
                      </p>
                    </div>
                  )}
                </Stack>
              </div>
            )}
          </div>
        </Card>
      )}
    </>
  );
};

export default ReportListItem;
