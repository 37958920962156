import Footer from 'components/Footer';
import React, { useEffect, useState } from 'react';
import { GetReportResponse } from 'hooks/useGetReport';
import {
  Banner,
  Card,
  Filters,
  Link,
  Modal,
  Page,
  ResourceList,
  TextStyle,
} from '@shopify/polaris';
import { SortOptions, SortType } from 'types/Utils';
import { determinePageSize, LocalPagination } from 'components/LocalPagination';
import ReportListItem from 'components/ReportListItem';
import styles from './NFTReportPage.module.scss';
import { useHistoryGoBack } from 'hooks/useHistoryGoBack';
import { Blockchain } from 'types/Blockchain';
import { orderBy } from 'lodash';
import ReportIconLarge from 'components/JsxIcons/Report/Report-Icon-large';
import { downloadFile } from 'utils/download';
import { ResendAllEmailValues, useResendAllEmails } from 'hooks/useResendEmail';
import ProgressBar from './ProgressBar';
import t from 'lib/translation';
import { truncateBlockchainAddress } from 'utils/stringFormatting';

interface ReportPageProps {
  reportData: GetReportResponse[];
  refetchReports: () => void;
}

function getBlockchainAndAddressFromUrl(): {
  blockchain: string;
  address: string;
} {
  const url = window.location.href;

  const pattern = /reports\/nft\/([^/]+)\/([0-9a-fA-Fx]+)/;

  const match = url.match(pattern);
  if (!match || match.length < 3) {
    throw new Error(
      'Invalid URL: Expected blockchain and address in the path.'
    );
  }

  const [, blockchain, address] = match;
  return { blockchain, address };
}

export const waitTime = 24;
export const waitTimeUnit = 'hours';

const NFTReportPage: React.FC<ReportPageProps> = ({
  reportData,
  refetchReports,
}) => {
  const result = getBlockchainAndAddressFromUrl();
  const historyGoBack = useHistoryGoBack();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const numberOfPages = Math.ceil((reportData?.length ?? 0) / pageSize);
  const [reportsAvailable, setReportsAvailable] =
    useState<GetReportResponse[]>(reportData);
  const [reportsAvailableAndSorted, setReportsAvailableAndSorted] =
    useState<GetReportResponse[]>(reportData);
  const [sortValue, setSortValue] = useState<SortType>('UPDATED_AT');
  const [queryValue, setQueryValue] = useState<string | undefined>(undefined);
  const [csvState, setCsvState] = useState<'idle' | 'preparing'>('idle');
  const isEmptyState = reportData?.length === 0;
  const { mutateAsync: resendAllEmails, isLoading } = useResendAllEmails();
  const [emailSent, setEmailSent] = useState(false);
  const totalReports = reportData.length;
  const completedReports = reportData.filter(
    (report) => report.status === 'COMPLETE'
  ).length; // Completed reports
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [isReportListItemDisabled, setIsReportListItemDisabled] =
    useState<boolean>(false);

  const reportsLimited = reportData.filter((report) => {
    if (
      report.status === 'COMPLETE' ||
      (report.mintingStarted !== null && report.status === 'RESERVED')
    )
      return false;
    if (!report.lastEmailSentAt) return true;
    const lastEmailTime = new Date(report.lastEmailSentAt);
    const msMultiplier =
      {
        minutes: 60 * 1000,
        hours: 60 * 60 * 1000,
        seconds: 1000,
      }[waitTimeUnit] || 60 * 60 * 1000;
    const waitTimeInMs = waitTime * msMultiplier;
    const cutoffTime = new Date(Date.now() - waitTimeInMs);
    return lastEmailTime < cutoffTime;
  });

  const resendEmails = async (values: ResendAllEmailValues) => {
    try {
      setIsReportListItemDisabled(true);
      await resendAllEmails({
        reports: values.reports,
      });
      setIsReportListItemDisabled(false);
      setShowSuccess(true);
      setEmailSent(true);
      refetchReports();
    } catch (err) {
      setIsReportListItemDisabled(false);
      setShowFail(true);
      const errorMessage =
        'There was an error when trying to resend the email. If the issue persists, please contact support@verisart.com.';
      setErrorMessage(errorMessage);
      setEmailSent(false);
    }
  };

  const [showSuccess, setShowSuccess] = useState(false);

  const handleSuccess = () => {
    setShowSuccess(true);
  };

  const [showFail, setShowFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const handleFail = (requestId?: string) => {
    setShowFail(true);
  };

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={[]}
      onQueryChange={(value) => setQueryValue(value)}
      onQueryClear={() => setQueryValue(undefined)}
      onClearAll={() => setQueryValue(undefined)}
    />
  );

  useEffect(() => {
    if (queryValue) {
      setReportsAvailable(
        reportData.filter(
          (report) =>
            report.ownerEmail
              ?.toLowerCase()
              .includes(queryValue.toLowerCase()) ||
            report.orderName?.toLowerCase().includes(queryValue.toLowerCase())
        )
      );
      setCurrentPage(1);
    } else {
      setReportsAvailable(reportData);
    }
  }, [queryValue, reportData]);

  useEffect(() => {
    switch (sortValue) {
      case 'NAME_ASC':
        setReportsAvailableAndSorted(
          orderBy(reportsAvailable, (r) => r.ownerEmail.toLowerCase(), 'asc')
        );
        break;
      case 'CREATED_AT':
        setReportsAvailableAndSorted(
          orderBy(reportsAvailable, 'report.createdAt', 'desc')
        );
        break;
      case 'UPDATED_AT':
        setReportsAvailableAndSorted(
          orderBy(reportsAvailable, 'report.updatedAt', 'desc')
        );
        break;
    }
  }, [sortValue, reportsAvailable]);

  const paginatedItems = determinePageSize(
    reportsAvailableAndSorted,
    currentPage,
    pageSize,
    numberOfPages
  );

  return (
    <>
      <Page
        breadcrumbs={[
          {
            content: `Report`,
            onAction: () => historyGoBack('/reports'),
          },
        ]}
        title={`${t(
          `blockchain.${result.blockchain}`
        )} - ${truncateBlockchainAddress(result.address)}`}
        primaryAction={{
          content: emailSent ? 'Emails sent' : 'Resend all emails',
          loading: isLoading,
          disabled: isLoading || isEmptyState,
          onAction: () => {
            setShowConfirmModal(true);
          },
        }}
        secondaryActions={[
          {
            loading: csvState === 'preparing',
            content: 'Download',
            onAction: async () => {
              const url = `/shopify/report/download/${result?.blockchain}/${result?.address}`;

              try {
                setCsvState('preparing');
                await downloadFile(url, {
                  name: `${result?.blockchain}-${result?.address}`
                    .toLowerCase()
                    .trim()
                    .replaceAll(' ', '-'),
                  extension: 'csv',
                });
              } finally {
                setCsvState('idle');
              }
            },
            disabled: csvState === 'preparing' || isEmptyState,
          },
        ]}
      >
        {isLoading && (
          <div style={{ paddingBottom: '1rem' }}>
            <Banner title="Sending emails" status="info">
              <p>Emails are being sent. You can safely leave this page.</p>
            </Banner>
          </div>
        )}
        {showSuccess && (
          <div style={{ paddingBottom: '1rem' }}>
            <Banner
              title="Emails successfully sent"
              status="success"
              onDismiss={() => setShowSuccess(false)}
            />
          </div>
        )}
        {showFail && (
          <div style={{ paddingBottom: '1rem' }}>
            <Banner
              title="Email failed to send"
              status="critical"
              onDismiss={() => setShowFail(false)}
            >
              <p>{errorMessage}</p>
            </Banner>
          </div>
        )}
        {!isEmptyState ? (
          <>
            <div style={{ marginBottom: '20px' }}>
              <ProgressBar total={totalReports} value={completedReports} />
            </div>
            <Card>
              <ResourceList
                sortValue={sortValue}
                sortOptions={SortOptions}
                onSortChange={(selected: SortType) => {
                  setSortValue(selected);
                }}
                filterControl={filterControl}
                items={paginatedItems}
                renderItem={(item: GetReportResponse) => (
                  <>
                    <ReportListItem
                      id={item.id}
                      mintingTo={item.mintingTo}
                      completedAt={item.completedAt}
                      ownerEmail={item.ownerEmail ?? ''}
                      status={item.status}
                      isCustodial={item.isCustodial}
                      isPredefined={item.isPredefined}
                      transactionAddress={item.transactionAddress}
                      blockchain={result?.blockchain as Blockchain}
                      mintingStarted={item.mintingStarted}
                      onSuccess={handleSuccess}
                      onFail={handleFail}
                      orderName={item.orderName}
                      createdAt={item.createdAt}
                      totalEmailCount={item.totalEmailCount}
                      lastEmailSentAt={item.lastEmailSentAt}
                      tokenId={item.tokenId}
                      setErrorMessage={setErrorMessage}
                      refetchReports={refetchReports}
                      isDisabled={isReportListItemDisabled}
                    />
                  </>
                )}
              />
            </Card>
            <div className={styles.pagination}>
              <LocalPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                numberOfPages={numberOfPages}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '25vh',
            }}
          >
            <div
              style={{
                maxWidth: '400px',
                width: '100%',
                padding: '20px',
                textAlign: 'center',
              }}
            >
              <Card>
                <div style={{ padding: '20px' }}>
                  <div style={{ marginBottom: '1rem', marginTop: '3rem' }}>
                    <ReportIconLarge />
                  </div>
                  <div style={{ fontSize: '18px', marginBottom: '2rem' }}>
                    <TextStyle variation="strong">
                      No reports for this contract
                    </TextStyle>
                  </div>
                  <div style={{ fontSize: '14px', marginBottom: '4rem' }}>
                    <TextStyle variation="subdued">
                      Reports for contracts will appear here. Once a product has
                      been purchased using this contract address, you can view
                      the reporting on who has claimed the product and who has
                      not.
                    </TextStyle>
                  </div>
                  <div style={{ marginBottom: '5rem' }}>
                    <Link
                      removeUnderline
                      url="/drops"
                      onClick={() => historyGoBack('/drops')}
                    >
                      Back to NFT drops
                    </Link>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        )}
      </Page>
      <Modal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title="Resend all emails"
        primaryAction={{
          content: 'Confirm',
          onAction: async () => {
            setShowConfirmModal(false);
            await resendEmails({
              reports: reportsLimited,
            });
          },
          disabled: reportsLimited?.length === 0,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setShowConfirmModal(false),
          },
        ]}
      >
        <Modal.Section>
          <TextStyle>
            Resend {reportsLimited?.length} emails. A reminder email will be
            sent to your customers. Please note that this action is final and
            cannot be undone.
          </TextStyle>
        </Modal.Section>
      </Modal>
      <Footer />
    </>
  );
};

export default NFTReportPage;
